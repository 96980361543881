import React, { CSSProperties } from "react"

import "./FakeArticle.scss"

export type FakeArticlePropsType = {
  className?: string
  style?: CSSProperties
  bottom: CSSProperties["bottom"]
  left: CSSProperties["left"]
  rotation: number
}

const FakeArticle = ({
  className,
  left,
  bottom,
  rotation,
  style,
}: FakeArticlePropsType) => {
  return (
    <div
      className={"fake-article-container " + (className ?? "")}
      style={
        {
          "--position-left": left,
          "--position-bottom": bottom,
          "--rotation": `${rotation}deg`,
          ...style,
        } as CSSProperties
      }
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default FakeArticle
