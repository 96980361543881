import { StaticImage } from "gatsby-plugin-image"
import React, { CSSProperties } from "react"
import { User, UserIds } from "@local-types/User"

import "./Avatar.scss"
const BACKGROUND_IMAGE_FILE_PATH =
  "../../../assets/avatar/background/background.png"

export const users: { [x in UserIds]: User } = {
  alexandre: {
    order: 1,
    firstname: "Alexandre",
    lastname: "Fauchard",
    devtoUsername: "alexandrefauchard",
    title: "Fullstack Developer",
    pictureFileLink: (
      <StaticImage
        src="../../../assets/avatar/people/Alex.png"
        alt={`Alexandre Fauchard`}
        style={
          {
            "--offset-factor": "0.09345",
            "--zoom-factor": "1.05",
          } as CSSProperties
        }
        width={200}
        height={200}
        placeholder="none"
      />
    ),
  },
  arthur: {
    order: 2,
    firstname: "Arthur",
    lastname: "Bouder",
    devtoUsername: "arthurbouder",
    title: "Fullstack Developer",
    pictureFileLink: (
      <StaticImage
        src="../../../assets/avatar/people/Arthur.png"
        alt={`Arthur Bouder`}
        style={
          {
            "--offset-factor": "0.0093",
            "--zoom-factor": "1.04",
          } as CSSProperties
        }
        width={200}
        height={200}
        placeholder="none"
      />
    ),
  },
  adrian: {
    order: 3,
    firstname: "Adrian",
    lastname: "Guery",
    devtoUsername: "adrianguery",
    title: "Front-end Developer",
    pictureFileLink: (
      <StaticImage
        src="../../../assets/avatar/people/Adrian.png"
        alt={`Adrian Guery`}
        placeholder="none"
        width={200}
        height={200}
      />
    ),
  },
  olivier: {
    order: 4,
    firstname: "Olivier",
    lastname: "Millochau",
    title: "Fullstack Developer",
    pictureFileLink: (
      <StaticImage
        src="../../../assets/avatar/people/Olivier.png"
        alt={`Olivier Millochau`}
        style={{ "--offset-factor": "-0.0187" } as CSSProperties}
        placeholder="none"
        width={200}
        height={200}
      />
    ),
  },
  justin: {
    order: 5,
    firstname: "Justin",
    lastname: "Martin",
    devtoUsername: "justinmartindev",
    title: "Fullstack Developer",
    pictureFileLink: (
      <StaticImage
        src="../../../assets/avatar/people/Justin.png"
        alt={`Justin Martin`}
        style={{ "--offset-factor": "0.0374" } as CSSProperties}
        placeholder="none"
        width={200}
        height={200}
      />
    ),
  },
}

export const getUserIdFromDevtoUsername = (username: string): UserIds =>
  Object.keys(users).find(
    (userId: string) => users[userId as UserIds].devtoUsername === username
  ) as UserIds

export type AvatarVariant = "small" | "medium" | "large"
export type AvatarPropTypes = {
  userId: UserIds
  variant?: AvatarVariant
} & React.EmbedHTMLAttributes<HTMLDivElement>

const Avatar = ({ variant = "medium", userId, ...props }: AvatarPropTypes) => {
  const user = users[userId]

  return (
    <div className={`avatar avatar-${variant}`} {...props}>
      <StaticImage src={BACKGROUND_IMAGE_FILE_PATH} alt={`office`} />
      {user.pictureFileLink}
    </div>
  )
}

export default Avatar
