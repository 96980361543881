import React, { CSSProperties } from "react"

import "./Pen.scss"

export type FakeArticlePropsType = {
  className?: string
  style?: CSSProperties
  bottom: CSSProperties["bottom"]
  left: CSSProperties["left"]
  rotation: number
}

const Pen = ({
  className,
  left,
  bottom,
  rotation,
  style,
}: FakeArticlePropsType) => {
  return (
    <div
      className={"pen-container " + (className ?? "")}
      style={
        {
          "--position-left": left,
          "--position-bottom": bottom,
          "--rotation": `${rotation}deg`,
          ...style,
        } as CSSProperties
      }
    >
      <div />
    </div>
  )
}

export default Pen
