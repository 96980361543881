import React, { CSSProperties } from "react"

import "./Pills.scss"

type PillsPropTypes = {
  backgroundColor?: CSSProperties["color"]
  labelColor?: CSSProperties["color"]
  children: React.ReactNode
} & React.EmbedHTMLAttributes<HTMLDivElement>

//Add a noMargin to the children
const Pills = ({
  backgroundColor,
  labelColor,
  children,
  style,
  className,
  ...props
}: PillsPropTypes) => {
  return (
    <div
      className={"pills " + className}
      style={
        {
          backgroundColor: backgroundColor ?? undefined,
          color: labelColor ?? undefined,
          ...style,
        } as CSSProperties
      }
      {...props}
    >
      {children}
    </div>
  )
}

export default Pills
