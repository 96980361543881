import CenteredContent from "@components/CenteredContent/CenteredContent"
import { Body, Heading } from "@components/ui/Typography/Typography"
import React from "react"
import "./BlogHero.scss"
import FakeArticle from "./FakeArticle/FakeArticle"
import Pen from "./Pen/Pen"

import chefclub from "./assets/chefclub.png"
import placemeet from "./assets/placemeet.png"

const BlogHero = () => {
  return (
    <section className="blog-hero-container">
      <CenteredContent>
        <Heading>Blog</Heading>
        <Body>
          Discover all the articles written by the Kezios team. Articles cover
          performance, architecture, code best practices, and more.
        </Body>
      </CenteredContent>
      <div className="blog-hero-curve">
        <div className="illustration-container">
          <div
            className="child-illustration-container mobile-hidden"
            style={{ right: "8.333%", width: "min(32.44%, 350px)" }}
          >
            <img
              src={chefclub}
              alt="chefclub"
              style={{
                left: "50%",
                bottom: "-50%",
                height: "308%",
                width: "50.57%",
              }}
            />
            <FakeArticle bottom="-160%" left="0px" rotation={-5} />
            <FakeArticle bottom="-90%" left="45%" rotation={-3} />
            <FakeArticle bottom="-65%" left="22.857%" rotation={5} />
          </div>
          <div
            className="child-illustration-container"
            style={{ left: "8.333%", width: "min(40%, 420px)" }}
          >
            <FakeArticle bottom="50%" left="-14.28%" rotation={-9} />
            <img
              src={placemeet}
              alt="placemeet"
              style={{
                left: 0,
                bottom: "-40%",
                height: "272%",
                width: "52.38%",
              }}
            />
            <FakeArticle bottom="-160%" left="57.14%" rotation={-5} />
            <Pen bottom="-85%" left="63.57%" rotation={-11} />
            <FakeArticle bottom="-95%" left="19.05%" rotation={5} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogHero
