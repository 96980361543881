import React from "react"
import Link from "@components/Gatsby/Link/Link"

import "./Button.scss"

interface PropTypes {
  variant?: "primary" | "secondary" | "tertiary"
  children?: React.ReactNode
  to: string
}
const Button = ({ variant = "primary", children, to }: PropTypes) => {
  return (
    <Link to={to}>
      <button className={`button-${variant}`}>{children}</button>
    </Link>
  )
}

export default Button
