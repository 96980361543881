import React from "react"
import "./ArticleCard.scss"
import Pills from "@components/ui/Pills/Pills"
import { Body, Heading } from "@components/ui/Typography/Typography"
import { upperFirstLetterCase } from "@utils/string"
import { getUserIdFromDevtoUsername } from "@components/ui/Avatar/Avatar"
import Button from "@components/ui/Button/Button"
import { PartialArticleData } from "@local-types/Article"
import ArticleAuthor from "@components/ArticleAuthor/ArticleAuthor"
import Link from "@components/Gatsby/Link/Link"

export type ArticleCardPropsType = PartialArticleData

const ArticleCard = ({
  title,
  slug,
  description,
  reading_time_minutes,
  published_timestamp,
  tags,
  social_image,
  user,
}: ArticleCardPropsType) => {
  const authorId = getUserIdFromDevtoUsername(user.username)

  return authorId ? (
    <Link to={`/articles/${slug}`} className="blog-article-link">
      <article className="blog-article-container">
        <div className="blog-article-thumbnail">
          <img
            src={social_image}
            className="post-image"
            alt={title}
            loading="lazy"
          />
        </div>
        <div className="blog-article-infos">
          <div className="blog-article-pills-container">
            {tags.map((tag, index) => (
              <Pills
                key={index}
                backgroundColor={"#F3F4F6"}
                labelColor={"#4B5563"}
              >
                {upperFirstLetterCase(tag)}
              </Pills>
            ))}
          </div>
          <Heading variant="h3" className="blog-article-title">
            {title}
          </Heading>
          <Body className="blog-article-description">{description}</Body>
          <Button variant="tertiary" to={`/articles/${slug}`}>
            Read more
          </Button>
          <ArticleAuthor
            authorId={authorId}
            published_timestamp={published_timestamp}
            reading_time_minutes={reading_time_minutes}
          />
        </div>
      </article>
    </Link>
  ) : null
}

export default ArticleCard
