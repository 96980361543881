import React from "react"

import "./ArticleAuthor.scss"
import Avatar, { AvatarVariant, users } from "@components/ui/Avatar/Avatar"
import { Body, BodyVariant } from "@components/ui/Typography/Typography"
import { UserIds } from "@local-types/User"

export type ArticleAuthorProps = {
  authorId: UserIds
  published_timestamp: string
  reading_time_minutes?: number
  variant?: AvatarVariant
} & React.EmbedHTMLAttributes<HTMLDivElement>

const ArticleAuthor = ({
  authorId,
  published_timestamp,
  reading_time_minutes,
  variant = "small",
  className,
  ...props
}: ArticleAuthorProps) => {
  const author = users[authorId]

  return (
    <div className={"blog-article-author " + className} {...props}>
      <Avatar userId={authorId} variant={variant} />
      <div className="blog-article-author-info">
        <Body variant={variant as BodyVariant}>
          {author.firstname} {author.lastname}
        </Body>
        <Body variant={variant as BodyVariant} color="#6B7280">
          {new Date(published_timestamp).toLocaleDateString()}{" "}
          {reading_time_minutes && <>• {reading_time_minutes}min read</>}
        </Body>
      </div>
    </div>
  )
}

export default ArticleAuthor
