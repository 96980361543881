import BlogHero from "@landingsParts/Blog/BlogHero/BlogHero"
import useArticles from "@hooks/useArticles"
import * as React from "react"

import "./articles.scss"
import SEO from "@components/SEO/SEO"
import Layout from "@components/layout/layout"
import ArticleCard from "@components/ArticleCard/ArticleCard"

// markup
const IndexPage = () => {
  const { articles } = useArticles()

  return (
    <main>
      <Layout>
        <SEO
          title="Blog: Kezios - A blog about software development"
          description={`The Kezios blog about software development.`}
        />

        <BlogHero />
        <div className="articles-list-content">
          {articles.map(article => (
            <ArticleCard {...article} key={article.slug} />
          ))}
        </div>
      </Layout>
    </main>
  )
}

export default IndexPage
