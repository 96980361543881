import { graphql, useStaticQuery } from "gatsby"
import { PartialArticleData } from "src/types/Article"

export default function useArticles() {
  const baseArticlesData: any[] = useStaticQuery(graphql`
    query ArticlesQuery {
      allDevToArticle(sort: { fields: published_timestamp, order: DESC }) {
        nodes {
          title
          slug
          description
          reading_time_minutes
          published_timestamp
          tags
          social_image
          user {
            username
          }
        }
      }
    }
  `).allDevToArticle.nodes.map((item: any) => item)

  const articles = baseArticlesData.map(
    (item: any) =>
      ({
        ...item,
      } as PartialArticleData)
  )

  return { articles }
}
